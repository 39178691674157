<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            default="today"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            type="search" 
            label="피심사팀" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전보건 내부심사 시정조치 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :editable="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name ==='ibmStepName'">
          <span v-if="props.row['ibmStepCd'] === 'IS00000001'" style="color:orange">{{props.row['ibmStepName']}}</span>
          <span v-else-if="props.row['ibmStepCd'] === 'IS00000005'" style="color:lime">{{props.row['ibmStepName']}}</span>
          <span v-else-if="props.row['ibmStepCd'] === 'IS00000010'" style="color:lightgreen">{{props.row['ibmStepName']}}</span>
          <span v-else-if="props.row['ibmStepCd'] === 'IS00000015'" style="color:teal">{{props.row['ibmStepName']}}</span>
          <span v-else-if="props.row['ibmStepCd'] === 'IS00000020'" style="color:blue">{{props.row['ibmStepName']}}</span>
          <span v-else>{{props.row['ibmStepName']}}</span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-improvement',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        deptCd: '',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalActionId' },
          { index: 1, colName: 'deptCd' },
          { index: 2, colName: 'saiInternalAuditClassNameDeptGubun' },
          { index: 3, colName: 'systemElementsNameGubun' },
          { index: 4, colName: 'saiInternalAuditChecklistId' },
          { index: 5, colName: 'saiInternalAuditChecklistId' },
          { index: 6, colName: 'saiInternalAuditChecklistId' },
          { index: 7, colName: 'saiInternalAuditChecklistId' },
          // { index: 9, colName: 'saiInternalAuditChecklistId' },
          // { index: 10, colName: 'saiInternalAuditChecklistId' },
        ],
        columns: [
          {
            name: 'actionName',
            field: 'actionName',
            label: '수행계획서명',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '피심사부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'saiInternalAuditClassName',
            field: 'saiInternalAuditClassName',
            label: 'ISO 구분',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '시스템요소',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순번',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '내부심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '내부심사 시 착안사항',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'saiInternalAuditResultName',
            field: 'saiInternalAuditResultName',
            label: '내부심사결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'result',
          //   field: 'result',
          //   label: '내부심사결과',
          //   child: [
          //     {
          //       name: 'sar0000001',
          //       field: 'sar0000001',
          //       label: '양호',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000005',
          //       field: 'sar0000005',
          //       label: '부적합',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000010',
          //       field: 'sar0000010',
          //       label: '관찰사항',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000015',
          //       field: 'sar0000015',
          //       label: '비적용',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //   ]
          // },
          {
            name: 'observation',
            field: 'observation',
            label: '관찰내용<br/>(부적합사항 및 보고서 No.)',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '개선요청 제목',
            align: 'left',
            type: 'link',
            style: 'width:240px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '개선구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:80px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            label: '요청부서정보',
            align: 'center',
            style: 'width:220px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.checklistResult.impr.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
